import React from 'react'
import Helmet from 'react-helmet'
import {graphql} from 'gatsby'
import Layout from '../../components/Layout'
import BlogTag from '../../components/BlogTag'

const TagsPage = ({
  data: { allMarkdownRemark: { group }, site: { siteMetadata: { title } } },
}) => (
  <Layout>
    <Helmet title={`Tags | ${title}`} />

    <div className='container'>
      <div className='columns'>
        <div className='column is-2 is-offset-1'>
          <h1 className='is-size-2 has-text-weight-semibold'>Tags</h1>
        </div>
        <div className='column is-7 is-offset-1'>
          {group.map(({fieldValue: tag}) => (
            <BlogTag tag={tag} />
          ))}
        </div>
      </div>
    </div>
  </Layout>
)

export default TagsPage

export const tagPageQuery = graphql`
  query TagsQuery {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(limit: 1000) {
      group(field: frontmatter___tags) {
        fieldValue
        totalCount
      }
    }
  }
`
